import React from 'react';
import ReactDOM from 'react-dom';
import App from "./App";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import green from '@material-ui/core/colors/green';

const theme = createMuiTheme({
    palette: {
        primary: {
            main: green[500],
        },
    },
});
ReactDOM.render(
    <ThemeProvider theme={theme}>
        <App />
    </ThemeProvider>, document.querySelector('#root'));